import { Button, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import http from '../utilities/http';
import { User } from '../utilities/interfaces';
import VyModal from './VyModal';

export const UserEmailUpdateModal: React.FC<{ user?: User; onClose: () => void }> = (props) => {
  const { user, onClose } = props;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const { addToast } = useToasts();

  async function updateEmail(values: { email: string }) {
    http
      .post('/user/update', { userid: user?._id, email: values.email })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        addToast('Error - bitte prüfen ob die E-Mail möglicherweise bereits vergeben ist', { severity: 'error' });
      });
  }

  return (
    <VyModal open={!!user} onClose={onClose} title="Nutzer updaten" maxWidth={800}>
      {() => (
        <form className="w-96 flex flex-col" onSubmit={handleSubmit(updateEmail)}>
          <TextField required disabled={isSubmitting} type="email" placeholder={user?.email} {...register('email', { required: true })} />
          <Button disabled={isSubmitting} type="submit">
            Speichern
          </Button>
        </form>
      )}
    </VyModal>
  );
};
