import { UiComponent } from './pagebuilder';

export interface Organization {
  allowedFonts: string[];
  integrations: { eventbrite: { active: boolean; token: string } };
  isFreeTier?: boolean;
  allowExceedingBudget?: boolean;
  name: string;
  users: User[];
  memberships: OrgMembership[];
  proAnalytics?: boolean;
  accountpreference: 'organizer' | 'exhibitor';
  platformPermissions: {
    resetSubUsersPassword: boolean;
    addSubUsersToExhibitors: boolean;
  };
}

export interface OrgMembership {
  _id: string;
  assignedMeetingroom?: string;
  organization: Organization;
  role: RoleName;
  user: User;
}

export interface EventTemplate {
  customBorderRadius: string;
  customFont: string;
  showEventLogout: boolean;
  signedUpSuccessSentenceCustom: string;
  individualNames: {
    emailReplyTo: string;
    homeTooltip: string;
    subBooths: string;
    emailSenderName: string;
    username: string;
    meetingFullNoticeText: string;
    deviceSelectionText: string;
    filter: string;
    myEventPage: string;
    appointments: string;
    pdfUploadsInBooth: string;
    contactShare: string;
    meetingrooms: string;
    conferencerooms: string;
    presentationrooms: string;
    orientationMode: string;
    orientationModeTooltip: string;
  };
  signedUpSentence: string;
  gradientOnImages: boolean;
  uiTemplate: string;
  complementColor: string;
  complementButtonTextColor: string;
  logo: string;
  mainColor: string;
  mainButtonTextColor: string;
  oneToOneBackgroundImage: string;
  presentationBackgroundImage: string;
  oneToOneSubPageBackgroundImage: string;
  presentationSubPageBackground: string;
  welcomeSentence: string;
  welcomeVideo: string;
  welcomeVideoType: string;
  welcomeVideoLabel: string;
  amountOfCurtains: number;
  logoHeight: string;
  showUserCount: boolean;
  showHostedBy: boolean;
  showUsernameRenameField?: boolean;
  useSpotlight?: boolean;
  signup: {
    infoBackgroundColor: string;
    infoFontColor: string;
    showDefaultDataProtectionNotice: boolean;
    dataPrivacySentence?: string;
  };
  live: {
    logoHeight: number;
    showEventName: boolean;
    showLogo: boolean;
    showVideoControls: boolean;
    showWelcomeSentence: boolean;
    customWelcomeSentence?: string;
    customEventDisplayName?: string;
  };
  landing: {
    showLogo?: boolean;
    showName?: boolean;
    showEndTime?: boolean;
    customWelcomeSentence?: string;
    customEventDisplayName?: string;
  };
  stackedPresentations: boolean;
}

export interface EventInterface {
  lock: { datetime_locked: Date; locked_by: User };
  displayname?: string;
  eventType?: 'hybrid' | 'digital';
  _id: string;
  datetime_created: string;
  datetime_end: string;
  datetime_start: string;
  organization: Organization;
  eventRole: ('host' | 'exhibitor')[];
  private: boolean;
  name: string;
  slug: string;
  region: string;
  description: string;
  status: string;
  language: string;
  legal: {
    imprintUrl?: string;
    dataPrivacyUrl?: string;
    termsUrl?: string;
    hideTerms?: boolean;
  };
  eventauth: {
    provider: string;
    mustRegister: boolean;
    registerPageUrl: string;
  };
  template: EventTemplate;
  videomodes: {
    oneOnOne: {
      active: boolean;
      version: string;
    };
    presentation: {
      active: boolean;
      version: string;
    };
    conference: {
      active: boolean;
      version: string;
    };
  };
  settings: {
    skipLandingPage: boolean;
    presentations: {
      blockTime?: number;
    };
    allowAppAccessBeforeEventStart: boolean;
    allowAccessToLive: boolean;
    showBoothsWithoutMeetingroom: boolean;
    showMeetingtime: boolean;
    showBoothStats: boolean;
    showBoothPotentialQueueUsers: boolean;
    hasNotifications: boolean;
    allowExternalSpeakers: boolean;
    allowMobileBrowser: boolean;
    enableMobileBrowserWarning: boolean;
    queuemode: string;
    allowUserRenameBooth: boolean;
    autoRedirFromLanding: boolean;
    disableEventAccessAfter: boolean;
    maxBoothPdfUpload: number;
    maxUserFileUpload: number;
    allowUserFileupload?: boolean;
    userFileUploadDescription: string;
    showAppNotice: boolean;
    showAppNoticeAndroid: boolean;
    showAppNoticeIos: boolean;
    skipLive: boolean;
    useBoothNumber: boolean;
    allowExternalSpeakerEdit: boolean;
    orientationMode?: boolean;
    suggestContactShare: boolean;
    allowFileUploadInMeeting: boolean;
    showQueueIndicator: boolean;
    preMeetingDeviceSelection: { video: boolean; audio: boolean; chat: boolean };
    timezone?: string;
    orientationDefaultOn?: boolean;
    alowDirectTicketcodeLogin?: boolean;
    intercomId?: string | null;
    showIntercomSupport?: boolean;
    allowPresenceTracking?: boolean;
    allowPusherDisconnectionNotification?: boolean;
    preventImageOptimization: boolean;
  };
  studios?: Meetingroom[];
  modules: {
    [key: string]: any;
    app: {
      active: boolean;
    };
    livestreams: {
      active: boolean;
      settings: {
        canSelectStreamProvider: boolean;
      };
    };
    guestmanagement: {
      active: boolean;
      settings: {
        attachTicketPDF: boolean;
        attachICS: boolean;
        autoApproveUser: boolean;
        linkedInAuth: boolean;
        registrationFields: RegistrationField[];
        authWithoutEmail: boolean;
        additionalFields: [
          {
            fieldtype: string;
            label: string;
            hidden: boolean;
            isRequired: boolean;
          }
        ];
        requireEmailConfirm: boolean;
        signupAfterEventPassed: boolean;
        maxRegistrationsPerUser?: boolean;
        showDefaultUsernameField?: boolean;
        authUserAfterSignupWhenPossible?: boolean;
      };
    };
    templatemanagement: {
      active: boolean;
      settings: {
        templateSelection: boolean;
        templateEditor: boolean;
      };
    };
    customTracking: {
      active: boolean;
      settings: {
        matomoUrl: string;
        matomoSiteId: string;
        fbCode: string;
        googleTagManager: string;
      };
    };
    challenges: {
      active: boolean;
    };
    studios: {
      active: boolean;
    };
    analytics: {
      active: boolean;
    };
    qacontrol: {
      active: boolean;
    };
    preuploadedmedia: {
      active: boolean;
      settings: {
        boothVideoUpload: boolean;
      };
    };
    advancedFilters: {
      active: boolean;
      settings: {
        allowSelfSelectValues: boolean;
      };
    };
    directorsControl: {
      active: boolean;
    };
    userReporting: {
      active: boolean;
      settings: {
        autoblock: {
          active: boolean;
          threshold: number;
        };
      };
    };
    feedbackEditing: {
      active: boolean;
    };
    networking: {
      active: boolean;
      settings: {
        roomlimit: number;
        participantlimit: number;
      };
    };
    booking: {
      active: boolean;
      settings: {
        start_time: number;
        end_time: number;
        defaultBooth: string;
      };
    };
    photobooths: {
      active: boolean;
      settings: {
        autoApprove: boolean;
      };
    };
    privileges: { active: boolean };
    matching: { active: boolean };
  };
  booths?: Booth[];
  pages: PageData[];
  challenges?: Challenge[];
  hybridSettings: {
    allowClickIn: boolean;
    allowClickOut: boolean;
    lockOnCheckin: boolean;
  };
  proAnalytics?: boolean;
}

export interface Meetingroom extends Record<string, any> {
  meetingroomid?: string;
  boothid?: string;
  booth: Booth;
  contactperson: string;
  contactposition: string;
  datetime_created: string;
  datetime_deleted: string | null;
  description: string;
  event: any;
  exhibitor: string;
  meetingimage: string;
  presentationBackground?: string | null;
  presentationSpotlight?: string | null;
  meetingtype: 'presentation' | 'meeting' | 'temporary' | 'conference' | 'networking' | 'breakout';
  name: string;
  vcard: any;
  settings: {
    allowDeepSettings: boolean;
    allowJoinViaFrontend: boolean;
    joinwithoutadmin: boolean;
    userlimit: number;
    visible: boolean;
    usermustknock: boolean;
    contactShareEnabled: boolean;
    userScreenshareEnabled: boolean;
    guestDevicesEnabled: boolean;
    hasChatComponent: boolean;
    participantListUserEnable: boolean;
    hasControlComponent: boolean;
    showUserList: boolean;
    canHaveBreakoutRooms: boolean;
    usesPassword: boolean;
    showBrowseEvent: boolean;
  };
  status: {
    canjoin: boolean;
    islive: boolean;
    lastjoin: string;
  };
  template: {
    title: string;
    headerEnabled: boolean;
    headerImage: string;
    backgroundColor: string;
    logo: string;
    tileBackgroundColor: string;
    hasNameplates: string;
    showKnockViewDescription: boolean;
  };
  displaystatus: string;
  _id: string;
  image: string;
  slot: Slot;
  subtitle?: string;
  subtext?: string;
  meetingappointments: any[];
  blocktime?: number;
  //Studio Related
  vonageSessionId: string;
  liveStream: string;
  broadcastId: string | null;
  archiveId: string | null;
  thumbnail: string | null;
  qaPageId: string | null;
}

export interface VCardModalData {
  meetingroomId?: string | null;
}

export interface PDFFile {
  _id: string;
  file: string;
  name: string;
}

export interface Organization {
  organizationid: string;
  backgroundImage: string;
  description: string;
  externalIdentifier: string;
  headerImages: string[];
  logo: string;
  slogan: string;
  welcomeVideo: string;
  welcomeVideoType: string;
  datetime_created: string;
  booths: Booth[];
  name: string;
  _id: string;
  category: string;
  offer: string;
  teaser: string;
  pdf: string;
  pdfUploads: PDFFile[];
  accountPreference: 'exhibitor' | 'organizer';
}

export type RoleName = 'PLATFORM_ADMIN' | 'ORGANIZATION_ADMIN' | 'PARTICIPANT_ANONYM' | 'ORGANIZATION_EXTERNAL_SPEAKER' | 'ORGANIZATION_SUBUSER';

export interface User {
  id: string;
  datetime_created: string;
  email: string;
  name: string;
  notificationToken: {
    web: string;
  };
  organization: Organization;
  role: RoleName;
  _id: string;
  resettoken?: string | null;
  emailOptIn: boolean;
  orgmemberships: any;
  assignedMeetingroom?: string;
  meetingassignments: any;
  avatar?: any;
  registrations?: EventRegistration[];
}

export interface UsersList {
  user: User;
  event: Event;
}

export interface RegistrationField {
  canEditInProfile: boolean;
  fieldType: string;
  label: string;
  helperText?: string;
  isHidden: boolean;
  valueOptions: string[];
  labelTranslations?: CustomTranslation[];
  helperTextTranslations?: CustomTranslation[];
  textValueOptions: {
    content: string;
    conditionalRequiredFields: any[];
    limit?: number;
    translations?: CustomTranslation[];
  }[];
  _id: string;
  datetime_deleted: null | Date;
  isRequired: boolean;
  isUserName: boolean;
  isEmailField: boolean;
  sortIndex: number;
  canMultiSelect: boolean;
  canEditInGuestMgmt: boolean;
  visibleToExhibitors: boolean;
  validation?: {
    min?: {
      value?: number;
      message?: string;
    };
    max?: {
      value?: number;
      message?: string;
    };
    pattern?: {
      value?: string;
      message?: string;
    };
  };
  trueText?: string;
  falseText?: string;
  variableUseCount?: number;
}

export interface EventRegistration {
  event: string;
  providertoken: string;
  provider: string;
  registrationFields: { value: string; field: RegistrationField }[];
  possibleRegistrationFields: RegistrationField[];
  status: string;
  _id: string;
  qrcode?: string;
  wallet?: string;
  ticket?: string;
  signup?: {
    name: string;
    event: string;
    registrationFields: any;
  };
  datetime_locked: Date | null;
  user?: User;
  reviewFeedback?: string | null;
  datetime_review_feedback_created?: Date | null;
  datetime_user_updated?: Date | null;
  lastCheckStatus?: any;
}

export type VideoType = 'recording' | 'youtube' | 'vystemboothvideo';

export interface WelcomeVideo {
  type: VideoType;
  video: string;
  videoApiId?: string;
  videoApiHls?: string;
  thumbnail?: string | null;
  isNew?: boolean;
  muxId?: string;
  muxPlaybackIds?: [
    {
      id: string;
      policy: 'public' | 'signed';
    }
  ];
  autoplay: boolean;
  booth?: string;
  datetime_deleted?: string | null;
  _id?: string;
}

export interface Booth {
  settings: {
    enableAppointments: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayPresentationsInBooths: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayContactShareInBooth: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayNetworkingroomsInBooth: {
      value: boolean;
    };
    externalSpeakers: {
      isEnabled: boolean;
      canExternalSpeakerEdit: boolean;
    };
    spotlight: {
      isEnabled: boolean;
    };
  };
  autoReply: {
    active: boolean;
    settings: {
      text: string;
      delayMinutes: number;
    };
  };
  datetime_hostinvite_sent?: Date;
  boothid?: string;
  datetime_created: string;
  allowedQueueForwarding: string;
  selectedQueueForwardingBooths: string[];
  event: EventInterface;
  organization: Organization;
  meetingrooms: Meetingroom[];
  conferencerooms: Meetingroom[];
  presentationrooms: Meetingroom[];
  networkingrooms: Meetingroom[];
  categoryFilterValues: string[];
  _id: string;
  maxMeetings: number;
  maxPresentations: number;
  maxConferences: number;
  maxPdfUploads: number;
  boothnumber?: string;
  allowExternalSpeakers?: boolean;
  name: string;
  offer?: string;
  teaser?: string;
  slogan?: string;
  sortIndex?: number;
  description?: string;
  welcomeVideo?: string;
  welcomeVideos?: WelcomeVideo[];
  boothVideos?: WelcomeVideo[];
  welcomeVideoType?: string;
  category?: string;
  logo?: string | null;
  headerImages?: string[];
  backgroundImage?: string | null;
  spotlightBackgroundImage?: string | null;
  pdfUploads?: PDFFile[];
  websiteUrl?: string;
  parentBooth?: string | Booth | null;
  childBooths?: Booth[];
  isSpotlight?: boolean;
  calculatedMeetingMinutes?: number;
}

export interface Slot {
  datetime_hostnotification_sent: string;
  datetime_end: string;
  datetime_start: string;
  meetingroom: Meetingroom;
  status: string;
  sortIndex: number;
  favorite?: FavoriteSlot;
  _id: string;
}

export interface FavoriteSlot {
  datetime_created: string;
  event: string;
  slot: Slot;
  user: string;
  _id: string;
}

export interface Status {
  eventid: string;
  userid: string;
  event: Event;
  user: User;
}

export interface Note {
  dateshareid: string;
  datetime_created: string;
  datetime_updated: null;
  note: string;
  _id: string;
}

export interface CategoryFilterValues {
  childCategoryFilter: string[];
  datetime_created: string;
  parentCategoryFilter: string;
  value: string;
  _id: string;
}

export interface Role {
  description: string;
  level: number;
  name: RoleName;
}

export type MailStatus = 'processed' | 'dropped' | 'open' | 'bounce' | 'delivered' | 'deferred';

export interface MailReport {
  count: number;
  status: MailStatus;
}

export interface UserCacheObject {
  user: {
    name: string;
    email: string;
    _id: string;
  };
  attendee?: {
    meetingid: string;
    role: string;
  };
}

export interface NetworkUser {
  _id: string;
  name: string;
  avatar: string;
  organization: string;
}

export interface ContactShare {
  datetime_created: string;
  email: string;
  meetingroomid: Meetingroom;
  boothid: Booth;
  message: string;
  name: string;
  _id: string;
}

export interface socialShareIconsType {
  url: string;
  icon: string;
  urlEncode: boolean;
  customSvgObject?: string;
}

export interface PhotoBooth {
  event: Event;
  datetime_created: string;
  user: User;
  file: string;
  approved: boolean;
  _id: string;
}

export interface Challenge {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
  isSequential: boolean;
  event: string;
  tasks: any[];
  winner: User;
}

export enum PrivilegeTypes {
  details,
  pages,
  booths,
  'booths.overview',
  'booths.sorting',
  'booths.filters',
  'booths.exhibitorInformation',
  'booths.settings',
  overview,
  'overview.exhibitorInformation',
  'overview.settings',
  presentations,
  'presentations.slots',
  'presentations.settings',
  livestreams,
  studios,
  networking,
  challenges,
  media,
  individualWatermarking,
  advancedConfiguration,
  'advancedConfiguration.overview',
  'advancedConfiguration.hybridEvent',
  'advancedConfiguration.individualName',
  'advancedConfiguration.emailTextTemplates',
  tracking,
  emails,
  directorControl,
  hybrid,
  'hybrid.analytics',
  'hybrid.control',
  qacontrol,
  reports,
  guestmanagement,
  'guestmanagement.overview',
  'guestmanagement.guestcommunication',
  'guestmanagement.profilefields',
  'guestmanagement.alternativeSignup',
  'guestmanagement.accessRule',
  'guestmanagement.settings',
  'guestmanagement.infectionTracking',
  'guestmanagement.guestValidation',
  photobooths,
  feedback,
  analytics,
  dashboard,
  checkin,
  clickin,
  guestlist,
}

export interface Privilege {
  _id: string;
  event: string;
  user: User;
  settings: {
    [key in keyof typeof PrivilegeTypes]: {
      accessible: boolean;
      [key: string]: boolean | { accessible: boolean };
    };
  };
}

export interface menuItem {
  id?: string;
  icon: any;
  title: any;
  href: string;
  openInNewTab?: boolean;
  role: [string?];
  excludeRole: [string?];
  settings: [string?];
  modules?: string[];
  conditional?: boolean;
  children?: childMenuItem[];
  hasBadge?: boolean;
  badgeTooltip?: string;
  eventType?: string;
  privilege?: keyof typeof PrivilegeTypes;
}

export interface GradientPickerValue {
  gradientColor: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  gradientSpread: number;
  gradientDirection: string;
}

export interface childMenuItem {
  title: string;
  subhref: string;
  icon?: any;
  eventType?: 'digital' | 'hybrid';
  privilege?: keyof typeof PrivilegeTypes;
}

export interface menuSection {
  title?: string;
  divider?: boolean;
  items: menuItem[];
}

export interface MeetingAppointment {
  _id: string;
  meetingroom: Meetingroom;
  user: string;
  datetime_start: Date;
  datetime_end: Date;
}

export interface media {
  _id: string;
  videoApiId: string;
  event: string;
  type: string;
  path: string;
  name: string;
  number: string;
  author: string;
  authorposition: string;
  file: string;
  description: string;
}

export interface Stage {
  number: number;
  name: string;
  slots: Slot[];
}

export interface PageData {
  linkSettings?: {
    openInNewTab: boolean;
    url: string;
  };
  _id: string;
  name: string;
  description?: string;
  event: any;
  template?: {
    buttonText?: string;
    siteTemplate?: string;
    advancedFilters?: string[];
    excludeFilters?: string[];
    backgroundImage?: string;
    backgroundColor?: string;
    backgroundBlur?: number;
    overlayColor?: string;
    overlayOpacity?: number;
    headerSentence?: string;
    showHeaderArrow?: boolean;
    headerArrowText?: string;
    showSearch?: boolean;
    showFilter?: boolean;
    htmlContent?: string | null;
    menuBar?: {
      backgroundColor?: string;
      textColor?: string;
      menuFade?: boolean;
      active?: boolean;
      showLogo?: boolean;
      alignLogo?: string;
      logoImage?: string;
      logoSize?: number;
      showEventInfo?: boolean;
    };
  };
  isLandingPage?: boolean;
  isHomePage?: boolean;
  isStatic?: boolean;
  isSignupPage?: boolean;
  isSignupSuccessPage?: boolean;
  visibility?: {
    visibleApp: boolean;
    visibleWeb: boolean;
  };
  stageSettings: {
    streamUrl: string;
    streamType: string;
    protected: boolean;
    placeHolderImage?: string;
    qa: Record<string, boolean>;
    chat: Record<string, boolean>;
    selfinfo: Record<string, boolean>;
    companyinfo: Record<string, boolean>;
    slido: any;
    livestream: string | null;
    //Deprecated
    iFrame: string | null;
    security: any;
  };
  presentationSettings: {
    excludeMedia: boolean;
  };
  pageIndex?: number;
  showOnLivePage: boolean;
  // TODO: Add type here
  uicomponents: UiComponent[];
  isRestrictOnRegistrationFields: boolean;
  restrictedByRegistrationFields: RestrictedFieldValuePair[];
  translations: CustomTranslation[];
  customURL?: string;
}

export interface CustomTranslation {
  language: locale;
  value: string;
}

export type locale = 'en' | 'de' | 'fr';

export interface RestrictedFieldValuePair {
  field: string;
  value: string;
}

export interface AttendeeFilterSetup {
  withLock: boolean;
  comparator: string;
  withGeneralFilters: boolean;
  lockStatus: 'locked' | 'unlocked';
  withSignupFormFilter: boolean;
  signupForm: string;
  profileFieldFilters?: {
    field: string;
    value: string;
  }[];
  excludeUsersAwaitingFeedback: boolean;
}

export interface QaCategory {
  id: string;
  _id: string;
  category: string;
  event: EventInterface;
  color: string;
}

export interface QaAnswerInterface {
  id: string;
  question: string;
  user: User;
  answer: string;
  datetime_created: string;
}

export interface MeetingQueuePosition {
  _id: string;
  user: string;
  meetingroom: Meetingroom;
  forwardedFromMeetingroom: string | null;
  datetime_created: Date;
  datetime_ack: Date;
  datetime_leave: Date;
  datetime_response: Date;
  jointoken: string;
  response: 'accept' | 'reject';
  datetime_deleted: Date;
  datetime_hidden: Date;
}

export interface QueueInvite {
  meetingroomId: string;
  contactPerson: string;
  contactPersonImage: string;
  boothHeroImage: string | null;
  queueId: string;
  datetime_sent: Date;
  validForSeconds: number;
}

export interface MatchingInvite {
  meetingId: string;
  contactPerson: string;
  contactPersonImage: string | null;
  boothHeaderImage: string | null;
  boothName: string;
  datetime_sent: Date;
  validForSeconds: number;
  message?: string | null;
}

export interface EventTemplate {
  _id: string;
  name: string;
  description: string;
  eventId: string;
  previewImage: string;
  datetime_created: Date;
  datetime_deleted: Date;
}

export interface LiveUsersInterface {
  id: string;
  _id: string;
  user: User;
  browsertabcount: {
    frontendcount: string;
    videosoftwarecount: string;
  };
  attendee: {
    meetingid: string;
  };
  attendeeid: string;
}

export interface LiveActivity {
  _id: string;
  id: string;
  name: string;
  contactperson: string;
  description: string;
  meetingtype: string;
  booth: Booth;
  count: number;
}

export interface EventReport {
  _id: string;
  datetime_created: string;
  convertTo: string;
  templateId: string;
  reportFile: string;
  datetime_finished_generation: string;
  error: {
    message: string;
  };
}

export type SupportedTicketPaymentTypes = 'free' | 'chargeable' | 'donation';
export type SupportedTicketPaymentCurrencies = 'USD' | 'EUR' | 'CHF' | 'GBP' | 'AUD';

export interface TicketType {
  price: number;
  name: string;
  paymentType: SupportedTicketPaymentTypes;
  description?: string;
  _id?: string;
  currency: SupportedTicketPaymentCurrencies;
  minPerOrder?: number;
  maxPerOrder?: number;
  amountAvailable?: number | null;
  allowTimeRestriction?: boolean;
  datetime_available?: Date;
  datetime_expiry?: Date;
  sortIndex: number;
  registrationEmailTemplate?: string | null;
  datetime_hidden?: boolean;
  isAvailable?: boolean;
}

export interface BasketItem {
  type: TicketType;
  singlePrice: number;
  amount: number;
}

export interface TicketOrder {
  _id: string;
  orderNumber?: string;
  rawPaymentData?: { method?: string };
  payment?: {
    status: string;
    amount?: { value: string; currency: SupportedTicketPaymentCurrencies };
  };
  basket: BasketItem[];
  billingEmail: string;
  event: EventInterface;
  registrations: EventRegistration[];
  invoice?: string;
  invoiceNumber?: number;
  datetime_cancelled: Date | null;
  datetime_deleted: Date | null;
  datetime_created: Date;
  costs: {
    subTotal: number;
    tax: number;
    gross: number;
    discount: number;
    taxRequired?: boolean;
  };
  billingData: {
    company?: string;
    firstname?: string;
    lastname?: string;
    street?: string;
    postcode?: string;
    city?: string;
    country?: string;
    email: string;
    vat?: string;
    comment?: string;
  };
  redirectUrl?: string;
  creditNote?: any;
}

export interface Promotion {
  fileIds: {
    en: string;
    de: string;
    fr: string;
  };
  urls: {
    en: string;
    de: string;
    fr: string;
  };
  version: number;
}

export enum PremiumServiceType {
  CustomAppstoreApp = 'customAppstoreApp',
  ProAnalytics = 'proAnalytics',
  CustomDomain = 'customDomain',
  Seo = 'seo',
}

export enum OrderStatus {
  Unprocessed = 'unprocessed',
  PendingPayment = 'pending-payment',
  Ongoing = 'ongoing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Declined = 'declined',
}

export interface PremiumServiceOrder {
  _id: string;
  user: User;
  organization: Organization;
  event: EventInterface;
  service: PremiumServiceType;
  additionalOptions?: { name: string; price: number }[];
  additionalData?: { name: string; value: string }[];
  basePrice: number;
  total: number;
  status: OrderStatus;
  featureIsActive?: boolean;
  datetime_created: string;
  datetime_deleted?: string;
}
